<template>
  <div>
    <vs-card>
      <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-table max-items="10" pagination search :data="skuData">
            <template slot="header">
              <vs-row class="gap" style="bottom: 10px; position: relative;">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                  <h3>SKU</h3>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                  <vs-button radius color="primary" icon="add" type="gradient" @click="addSku()"></vs-button>
                </vs-col>
              </vs-row>
            </template>
            <template slot="thead">
              <vs-th sort-key="email">
                Name
              </vs-th>
              <vs-th sort-key="email">
                Action
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">
                  {{ data[indextr].name }}
                </vs-td>

                <vs-td :data="data[indextr].id">
                  <vs-row>
                    <vs-col vs-type="flex" vs-w="12">
                      <vs-button radius color="success" type="gradient" icon="create" @click="editSku(data[indextr])">
                      </vs-button>&nbsp;&nbsp;
                      <vs-button radius color="danger" type="gradient" icon="delete" @click="deleteSku(data[indextr])">
                      </vs-button>
                    </vs-col>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
    </vs-card>

    <vs-popup title="Are You Sure ?" :active.sync="deleteSkuPopup">

      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">You Can't Undo the Action you will
          perform
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
          <vs-button type="gradient" size="small" style="font-size:14px" color="success" @click="deleteSkuName(skuId)">
            Delete</vs-button>&nbsp;&nbsp;
          <vs-button type="gradient" size="small" style="font-size:14px" color="danger" @click="closePopup()">Cancel
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup title="Edit SKU" :active.sync="editSkuPopup">
      <vs-input class="inputx" placeholder="Name" v-model="skuName" />
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        </vs-col>

        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
          <vs-button type="gradient" size="small" style="font-size:14px" color="warning" @click="updateSkuName(skuId)">
            Update</vs-button>&nbsp;&nbsp;
          <vs-button type="gradient" size="small" style="font-size:14px" color="danger" @click="closePopup()">Cancel
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup title="Add SKU" :active.sync="addSkuPopup">
      <!-- <vs-row>
        <vs-col vs-w="6">
          <vs-input class="inputx" placeholder="Name" v-model="skuName" label="Name"/>
        </vs-col>
        <vs-col vs-w="6">
          <vs-select autocomplete label="Categories" v-model="seleted_category" width="250px">
            <vs-select-item :key="index" :value="item.text" :text="item.text"
              v-for="(item, index) in categories_option" />
          </vs-select>
        </vs-col>
      </vs-row> -->
       <vs-row>
        <vs-col vs-w="12">
          <vs-input class="inputx" placeholder="Name" v-model="skuName" label="Name"/>
        </vs-col>
       
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" style="margin-top: 2%;">
          <vs-button type="gradient" size="small" style="font-size:14px" color="success" @click="addSkuName()">Add
          </vs-button>&nbsp;&nbsp;
          <vs-button type="gradient" size="small" style="font-size:14px" color="danger" @click="closePopup()">Cancel
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
// import axios from "axios";
// import constants from "../../../constants.json";
import ApiServices from "../../ApiServices";

export default {
  data() {
    return {
      skuData: [],
      skuName: "",
      seleted_category:"CPA",
      editSkuPopup: false,
      skuId: null,
      deleteSkuPopup: false,
      addSkuPopup: false,
      categories_option: [{ text: 'CPA' },
      { text: 'CMA' },
      { text: 'CFA' },
      { text: 'FRM' },
      { text: 'Digital Marketing' },
      { text: 'SHRM' },
      { text: 'Wiley Black' },
      { text: 'Product Management' },
      { text: 'AL/ML SQL' },
      { text: 'HBR' },
      { text: 'KPMG' },
      { text: 'Misc' },],
    };
  },
  mounted() {
    this.getSkuData();
  },
  methods: {
    addSku() {
      this.addSkuPopup = true;
      this.skuName = "";
    },
    addSkuName() {
      let obj = {
        name: this.skuName,
      };
      ApiServices.addSkuName(obj)
        .then((response) => {
          console.log("responce add sku", response);
          this.skuName = obj;
          this.getSkuData();
          this.skuName = "";
          this.$vs.notify({
            title: 'Created',
            text: '',
            color: 'success',
          });
          this.addSkuPopup = false;
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
        });
    },
    getSkuData() {

      ApiServices.getSkuData()
        .then((response) => {
          // this.selectSpocCity(response);
          console.log("get sku data", response);
          this.skuData = response.data.data;
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
        });
    },
    editSku(val) {
      this.skuId = val.id;
      this.editSkuPopup = true;
      this.skuName = val.name;
    },
    updateSkuName(id) {
      let obj = {
        name: this.skuName,
      };
      ApiServices.getSkuData(id, obj)
        .then((response) => {
          console.log("responce", response);
          this.editSkuPopup = false;
          this.getSkuData();
          this.skuId = null;
          this.skuName = "";
          this.$vs.notify({
            title: 'Updated',
            text: '',
            color: 'success',
          });
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
        });
    },
    closePopup() {
      this.editSkuPopup = false;
      this.deleteSkuPopup = false;
      this.addSkuPopup = false;
    },
    deleteSku(val) {
      this.skuId = val.id;
      this.deleteSkuPopup = true;
    },
    deleteSkuName(id) {
      ApiServices.getSkuData(id)
        .then((response) => {
          console.log("This Sku has been Deleted", response);
          this.getSkuData();
          this.$vs.notify({
            title: 'Deleted',
            text: '',
            color: 'success',
          });
          this.deleteSkuPopup = false;
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
        });
    },
  },
};
</script>

<style>
</style>
