<template>
  <div>
      <skutable/>
  </div>
</template>

<script>
import skutable from "../../src/components/inventory/Skutable.vue"
export default {
    
    components:{
        skutable
    }
}
</script>

<style>

</style>